<template>
  <el-container style="height:calc(100vh);">
    <el-header class="header">
      <div class="top">
        <span class="left el-icon-arrow-left" @click="returnOn"></span>
        低库存预警
        <span class="right"></span>
      </div>
      <div class="btm">
        <!-- <div class="box" @click="warehouseDrawerShow=true">
          <div class="left">
            全部仓库
          </div>
          <template>
            <div v-if="warehouseName" class="right">{{warehouseName}}</div>
            <div v-else class="right el-icon-arrow-right"></div>
          </template>
        </div>
        <div class="box" @click="classDrawerShow=true">
          <div class="left">
            全部分类
          </div>
          <template>
            <div v-if="className" class="right">{{className}}</div>
            <div v-else class="right el-icon-arrow-right"></div>
          </template>
        </div>
        <div class="box" @click="brandDrawerShow=true">
          <div class="left">
            全部品牌
          </div>
          <template>
            <div v-if="brandName" class="right">{{brandName}}</div>
            <div v-else class="right el-icon-arrow-right"></div>
          </template>
        </div> -->
        <div class="box-ipt">
          <div class="search">
            <el-input placeholder="请输入商品名称" clearable prefix-icon="el-icon-search" v-model="searchVal"
              @keyup.enter.native="searchChange">
            </el-input>
            <el-button type="primary" @click="searchChange">搜索</el-button>
          </div>
        </div>
      </div>
    </el-header>
    <el-main>
      <div class="table">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="name" label="商品名称">
          </el-table-column>
          <el-table-column prop="stock" label="当前库存">
            <template slot-scope="scope">
              <span style="color:#f66c84;">{{scope.row.stock}}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="time" label="预计剩余可售天数">
          </el-table-column> -->
          <el-table-column prop="warmtime" label="预警日期">
            <template slot-scope="scope">
              <span style="color:#f66c84;">{{scope.row.warmtime | timeform(false)}}</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="10"
          layout="prev, pager, next, jumper"
          :total="tableData.length">
        </el-pagination>
        <!-- <el-pagination background @current-change="handleCurrentChange" :current-page="currentPage"
           :page-size="pagesize" @size-change="handleSizeChange" layout="total,sizes ,prev,
          pager, next,jumper" :total="tableData.length">
        </el-pagination> -->
      </div>
    </el-main>
    <!-- 仓库 -->
    <el-drawer :visible.sync="warehouseDrawerShow" :show-close="false" direction="rtl" :before-close="handleClose">
      <template slot="title">
        <div class="drawer-title">
          <span></span><span class="center">选择仓库</span><span class="el-icon-close"
            @click="warehouseDrawerShow=false"></span>
        </div>
      </template>
      <el-container style="height:calc(100vh - 52px);">
        <el-main class="cell-list">
          <el-radio-group v-model="warehouseRadio">
            <el-radio :label="'仓库名称'+item" v-for="(item,i) in 20" :key="i">
              <div class="dialog-title">
                仓库名称{{item}}
              </div>
            </el-radio>
          </el-radio-group>
        </el-main>
        <el-footer>
          <div class="footer">
            <div class="btn left" @click="warehouseDrawerShow=false">重置</div>
            <div class="btn right" @click="warehouseConfirm">确定</div>
          </div>
        </el-footer>
      </el-container>
    </el-drawer>
    <!-- 分类 -->
    <el-drawer :visible.sync="classDrawerShow" :show-close="false" direction="rtl" :before-close="handleClose">
      <template slot="title">
        <div class="drawer-title">
          <span></span><span class="center">选择分类</span><span class="el-icon-close"
            @click="classDrawerShow=false"></span>
        </div>
      </template>
      <el-container style="height:calc(100vh - 52px);">
        <el-main class="cell-list">
          <el-radio-group v-model="classRadio">
            <el-radio :label="'分类名称'+item" v-for="(item,i) in 20" :key="i">
              <div class="dialog-title">
                分类名称{{item}}
              </div>
            </el-radio>
          </el-radio-group>
        </el-main>
        <el-footer>
          <div class="footer">
            <div class="btn left" @click="classDrawerShow=false">重置</div>
            <div class="btn right" @click="classConfirm">确定</div>
          </div>
        </el-footer>
      </el-container>
    </el-drawer>
    <!-- 品牌 -->
    <el-drawer :visible.sync="brandDrawerShow" :show-close="false" direction="rtl" :before-close="handleClose">
      <template slot="title">
        <div class="drawer-title">
          <span></span><span class="center">选择品牌</span><span class="el-icon-close"
            @click="brandDrawerShow=false"></span>
        </div>
      </template>
      <el-container style="height:calc(100vh - 52px);">
        <el-header>
          <div class="search">
            <el-input placeholder="请输入商品名称或编号" clearable prefix-icon="el-icon-search" v-model="brandSearchVal">
            </el-input>
            <el-button type="primary">搜索</el-button>
          </div>
        </el-header>
        <el-main class="cell-list">
          <el-radio-group v-model="brandRadio">
            <el-radio :label="'品牌名称'+item" v-for="(item,i) in 20" :key="i">
              <div class="dialog-title">
                品牌名称{{item}}
              </div>
            </el-radio>
          </el-radio-group>
        </el-main>
        <el-footer>
          <div class="footer">
            <div class="btn left" @click="brandDrawerShow=false">重置</div>
            <div class="btn right" @click="brandConfirm">确定</div>
          </div>
        </el-footer>
      </el-container>
    </el-drawer>
  </el-container>
</template>

<script>
  export default {
    data() {
      return {
        searchVal: '',
        warehouseDrawerShow: false,
        warehouseName: '',
        warehouseRadio: '',
        classDrawerShow: false,
        className: '',
        classRadio: '',
        brandDrawerShow: false,
        brandSearchVal: '',
        brandName: '',
        brandRadio: '',
        // 分页
        tableData: [],
        currentPage: 1, // 当前页码

        pagesize: 10, // 每页显示的行数
        warningList:[]
      }
    },
    computed: {
      tableDataShow() {
        return this.tableData.slice((this.currentPage - 1) * this.pagesize, this.currentPage * this.pagesize)
      }
    },
    created() {
      this.getWarning()
    },
    methods: {
      //获取低库存预警列表
      getWarning(){
        this.$http.post('api/store/stock/distock',{
          page:this.currentPage,
          keyword:this.searchVal
        }).then(res =>{
          this.tableData = res.data.data.data
          // console.log(res)
        })
      },
      // 返回上级
      returnOn() {
        this.$router.go(-1)
      },
      // 搜索
      searchChange() {
        this.currentPage == 1
        this.getWarning()
        // if (this.searchVal.trim()) {
        //   console.log(this.searchVal.trim());
        // } else {
        //   this.$message.error('搜索内容不能为空')
        // }
      },
      // 分页方法
      handleCurrentChange(val) {
        this.currentPage = val;
        this.getWarning()
        // console.log(val);
      },
      handleSizeChange(val) {
        this.pagesize = val
      },
      warehouseConfirm() {
        this.warehouseName = this.warehouseRadio
        this.warehouseDrawerShow = false
      },
      classConfirm() {
        this.className = this.classRadio
        this.classDrawerShow = false

      },
      brandConfirm() {
        this.brandName = this.brandRadio
        this.brandDrawerShow = false

      },
      handleClose() {
        this.warehouseDrawerShow = false
        this.classDrawerShow = false
        this.brandDrawerShow = false
      }
    }
  };

</script>
<style lang="less" scoped>
  .el-header {
    background-color: #fff;
    font-weight: bold;
    font-size: 16px;
    color: #333;
    height: auto !important;
    text-align: center;
    padding: 0;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      border-bottom: 1px solid #e9e9e9;
      padding: 0 20px;


      .left {
        color: #46a6ff;
        font-size: 24px;
      }

      .right {
        font-size: 14px;
        font-weight: normal;
        color: #409eff;
      }
    }

    .btm {
      display: flex;
      justify-content: space-around;
      align-items: center;
      background-color: #f9f9f9;
      padding: 20px;

      .box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 22%;
        min-width: 150px;
        padding: 10px;
        margin-right: 1%;
        border: 1px solid #f2f2f2;
        background-color: #fff;
        font-size: 14px;
        color: #666;
        font-weight: normal;
        box-sizing: border-box;

        .el-icon-arrow-right {
          color: #999;
        }
      }

      .box-ipt {
        width: 31%;

        .search {
          display: flex;
          justify-content: space-around;
          align-items: center;

          .el-button--primary {
            height: 40px;
            margin-left: -6px;
            z-index: 1;
            font-size: 12px;
            border-radius: 0 5px 5px 0;

          }

          .el-button--primary:focus,
          .el-button--primary:hover,
          .el-button:active,
          .el-button:focus,
          .el-button:hover {
            color: #fff;
            border-color: #409eff;
            background-color: #409eff;
          }

          /deep/ .el-input__inner {
            height: 40px;
            border: 1px solid #dcdfe6;
            border-right: 0;
            border-radius: 5px 0 0 5px;

            &:focus,
            &:hover {
              border-color: #dcdfe6;
            }
          }
        }
      }
    }
  }

  /deep/ .el-main {
    padding: 0 20px 20px !important;
    background-color: #f9f9f9;

    .table {
      background-color: #fff;
      padding: 20px;
      height: auto;
      border: 1px solid #f2f2f2;
      border-radius: 2px;

      .el-pagination {
        text-align: center;
        padding: 10px;
      }
    }
  }

  /deep/ .el-drawer {
    min-width: 400px;

    .el-drawer__header {
      padding: 0;
      margin: 0;
    }

    .drawer-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px;
      border-bottom: 1px solid #f2f2f2;

      .center {
        font-size: 16px;
        font-weight: bold;
      }

      .el-icon-close {
        font-size: 20px;
        color: #409eff;
      }
    }

    .el-header {
      padding: 20px;

      .search {
        display: flex;
        justify-content: space-around;
        align-items: center;

        .el-button--primary {
          height: 40px;
          margin-left: -6px;
          z-index: 1;
          font-size: 12px;
          border-radius: 0 5px 5px 0;

        }

        .el-button--primary:focus,
        .el-button--primary:hover,
        .el-button:active,
        .el-button:focus,
        .el-button:hover {
          color: #fff;
          border-color: #409eff;
          background-color: #409eff;
        }

        /deep/ .el-input__inner {
          height: 40px;
          border: 1px solid #dcdfe6;
          border-right: 0;
          border-radius: 5px 0 0 5px;

          &:focus,
          &:hover {
            border-color: #dcdfe6;
          }
        }
      }
    }

    .cell-list {
      width: 100%;
      padding: 10px 20px 20px !important;

      .el-radio-group {
        width: 100%;
        position: relative;

        .el-radio__input.is-checked+.el-radio__label {
          color: #666;
        }

        .el-radio {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #f2f2f2;
          padding: 10px 0;
          margin-bottom: 10px;
          margin-right: 0;

          .dialog-title {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 12px;
          }

          .el-radio__input {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

    }

    .el-footer {
      .footer {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 100%;


        .btn {
          width: 40%;
          text-align: center;
          border: 1px solid #409eff;
          border-radius: 50px;
          line-height: 40px;
        }

        .left {
          color: #409eff;
        }

        .right {
          background-color: #409eff;
          color: #fff;
          font-weight: bold;
        }
      }
    }

  }

</style>
